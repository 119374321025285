import React from "react";
import SectionTitle from "./SectionTitle";
import Button from "./Button";
import { FaYoutube } from "react-icons/fa";
import gallery1 from "../assets/images/gallery1.png";
import gallery2 from "../assets/images/gallery2.png";
import gallery3 from "../assets/images/gallery3.png";
import gallery4 from "../assets/images/gallery4.png";
import gallery5 from "../assets/images/gallery5.png";
import gallery6 from "../assets/images/gallery6.png";
import Carousel from "./Carousel";

const PastEvents = () => {
  const images = [gallery1, gallery2, gallery3, gallery4, gallery5, gallery6];
  return (
    <div className='w-full flex flex-col items-center justify-center gap-10 my-[8rem]'>
      <SectionTitle title={"Past Events"} />
      <p className='w-[80%] xs:text-[12px] lg:w-[60%]'>
        Swipe through our gallery and relive the hilarity of our last events! 😂📸
      </p>
      <div className='gradient-divider w-3/4'></div>
      <p className='w-[80%] xs:text-[18px] text-[24px] font-black'>Caught in the Middle ‘23</p>
      <div>
        <Carousel images={images} />
      </div>
      <a target='_blank' href='https://www.youtube.com/watch?v=SG-ihOX8Wuc' rel='noreferrer'>
        <Button>
          <FaYoutube color='red' size={30} />
          <h5 className='font-bold text-[16px] gradient-text'>Watch on Youtube </h5>
        </Button>
      </a>
      <div className='gradient-divider w-3/4'></div>
    </div>
  );
};

export default PastEvents;
