import React from "react";
import logo from "../assets/images/logo.png";
import { MdFacebook } from "react-icons/md";
import { FaTwitter, FaYoutube } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className='footer h-[40vh]'>
      <div className='flex flex-col items-center justify-center h-full'>
        <img src={logo} alt='logo' />
        <div className='flex items-center justify-center gap-[3.5rem] mt-10'>
          <a href='/'>
            <MdFacebook size={20} />
          </a>
          <a href='/'>
            <FaTwitter size={20} />
          </a>
          <a href='/'>
            <FaYoutube size={20} />
          </a>
        </div>
        <div className='gradient-divider w-2/3 my-4'></div>
        <h4>© Okokobioko Comedy Special | 2024</h4>
      </div>
    </footer>
  );
};

export default Footer;
