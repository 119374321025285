import { useEffect, useState } from "react";
import device from "../constants/breakpoint";

export const useMatchMediaQuery = (mediaQuery = device.tablet) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    setMatches(window.matchMedia(mediaQuery).matches);
  }, [mediaQuery]);
  useEffect(() => {
    function handleResize() {
      setMatches(window.matchMedia(mediaQuery).matches);
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [mediaQuery]);

  return matches;
};
