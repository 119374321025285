import React from "react";
import SectionTitle from "./SectionTitle";
import Frame from "./Frame";
import pararan from "../assets/images/paranran.png";
import okokobioko from "../assets/images/okokobioko.png";
import clemento from "../assets/images/clemento.png";
import amponsah from "../assets/images/amponsah.png";
import lekzy from "../assets/images/lekzy.png";
import parrot from "../assets/images/parrot.png";
import { useMatchMediaQuery } from "../hooks/useMediaQuery";
import device from "../constants/breakpoint";

const LineUps = () => {
  const isTablet = useMatchMediaQuery(device.tablet);
  return (
    <div id="lineUps" className='w-full flex flex-col items-center justify-center gap-10 my-[8rem]'>
      <SectionTitle title={"Line Ups"} />
      <div className='flex xmd:flex-col gap-3 my-[5rem]'>
        <div className='flex xs:flex-col l:flex-col gap-8 xmd:my-6'>
          <Frame src={pararan} name={"Pararan"} role={"Comedian (Nigeria)"} height={"300px"} />
          <Frame src={parrot} name={"Parrot Mouth"} role={"Comedian (Nigeria)"} height={"300px"} />
        </div>
        <div className='mt-[-70px] xs:flex-col flex l:flex-col gap-8 xmd:py-[3rem] xmd:mt-2'>
          <Frame src={okokobioko} name={"Okokobioko"} role={"Comedian (Ghana)"}  height={isTablet ? "300px" : "400px"} />
          <Frame src={lekzy} name={"Oh Joo"} role={"Comedian (Ghana)"} height={"300px"} />
        </div>
        <div className='xs:flex-col flex l:flex-col gap-8 xmd:my-6'>
        <Frame src={amponsah} name={"OB AMPONSAH"} role={"Comedian (Ghana)"}  height={"300px"} />
          <Frame src={clemento} name={"Ranzy"} role={"Comedian (Ghana)"} height={"300px"} />
        
        </div>
        
      </div>
      <div className='my-4'>
        <h5 className='font-black text-[40px] xs:text-[30px]'> and many</h5>
        <h5 className='font-black text-[40px] xs:text-[30px]'> more...</h5>
      </div>
    </div>
  );
};

export default LineUps;
