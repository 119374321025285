import React, { useState } from "react";
import logo from "../assets/images/logo.png";
import { RxHamburgerMenu } from "react-icons/rx";
import { IoMdClose } from "react-icons/io";

const Header = () => {
  const [openMenu, setMenuOpen] = useState(false);

  const handleNavigation = (e, target) => {
    e.preventDefault();
    const section = document.querySelector(target);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
    setMenuOpen(false);
  };

  return (
    <header className='w-full relative max-w-[1400px] mx-auto'>
      <div className='flex w-full justify-between items-center bg-transparent gap-4'>
        <img src={logo} alt='logo' />
        <ul className='flex items-center justify-end gap-10 flex-1 sm:hidden'>
          <li>
            <a className='headerLink' href='/'>
              Home
            </a>
          </li>
          <li>
            <a className='headerLink' href='#lineUps' onClick={(e) => handleNavigation(e, "#lineUps")}>
              Lineups
            </a>
          </li>
          <li>
            <a className='headerLink' href='#tickets' onClick={(e) => handleNavigation(e, "#tickets")}>
              Tickets
            </a>
          </li>
        </ul>
        {openMenu ? (
          <IoMdClose className='md:hidden' size={40} onClick={() => setMenuOpen(false)} />
        ) : (
          <RxHamburgerMenu className='md:hidden' size={40} onClick={() => setMenuOpen(true)} />
        )}
      </div>
      <div
        className={`md:hidden absolute top-[-40px] left-[-80px] w-[100%] bg-transparent text-white h-[110vh] blur-backdrop ${
          openMenu ? "translate-x-0 overflow-y-hidden" : "translate-x-[-1000px]"
        } ease-in-out duration-500`}>
        <ul className='w-full h-full flex flex-col items-center justify-evenly gap-4'>
          <li>
            <a className='headerLink' href='/'>
              Home
            </a>
          </li>
          <li>
            <a className='headerLink' href='#lineUps' onClick={(e) => handleNavigation(e, "#lineUps")}>
              Lineups
            </a>
          </li>
          <li>
            <a className='headerLink' href='#tickets' onClick={(e) => handleNavigation(e, "#tickets")}>
              Tickets
            </a>
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
