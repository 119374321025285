import React from "react";
import { motion } from "framer-motion";
import mx24 from "../assets/images/mx24.png";
import kss from "../assets/images/kss.png";
import pulse from "../assets/images/pulse.png";
import tv3 from "../assets/images/tv3.png";
import shobiz from "../assets/images/shobiz.png";
import spectator from "../assets/images/spectator.png";
import yen from "../assets/images/yen.png";
import PartnerFrame from "./PartnerFrame";
import { useMatchMediaQuery } from "../hooks/useMediaQuery";
import device from "../constants/breakpoint";

const PartnerSlide = () => {
  const slides = [
    {
      src: kss,
    },
    {
      src: mx24,
    },
    {
      src: pulse,
    },
    {
      src: tv3,
    },
    {
      src: shobiz,
    },
    {
      src: spectator,
    },
    {
      src: yen,
    },
  ];
  const duplicatedSlides = [...slides, ...slides];
  const isMobile = useMatchMediaQuery(device.mobile);

  return (
    <div className='relative h-full overflow-hidden py-12 bg-black mx-auto' style={{ width: "100%" }}>
      <div className='absolute inset-0 z-20 before:absolute before:left-0 before:top-0 before:w-1/4 before:h-full before:bg-gradient-to-r before:from-black before:to-transparent before:filter before:blur-3 after:absolute after:right-0 after:top-0 after:w-1/4 after:h-full after:bg-gradient-to-l after:from-black after:to-transparent after:filter after:blur-3'></div>

      <motion.div
        className='flex gap-[3rem]'
        animate={{
          x: ["0%", "-100%"],
          transition: {
            ease: "linear",
            duration: 15,
            repeat: Infinity,
          },
        }}>
        {duplicatedSlides.map((slide, index) => (
          <div
            key={index}
            className='flex-shrink-0'
            style={{ width: isMobile ? `${200 / slides.length}%` : `${100 / slides.length}%` }}>
            <PartnerFrame src={slide.src} />
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default PartnerSlide;
