import React from "react";
import SectionTitle from "./SectionTitle";

import Button from "./Button";

const Tickets = () => {
  return (
    <div id='tickets' className='w-full flex flex-col items-center justify-center gap-10 my-[8rem]'>
      <SectionTitle title={"Tickets"} />
      <p className='w-[80%] xs:text-[12px] lg:w-[60%]'>
        Get ready to laugh your socks off at 'Standing Strong'—the show so funny, even our jokes are standing room only!
        🎤😂 Get your tickets here before they disappear faster than a punchline!
      </p>
      {/* <div className='trailer-placeholder h-[70vh] xs:h-[60vh] max-h-[600px] w-full video-responsive my-6'>
        <iframe
          src='https://vsprocessorpro.com/ev/BuyTicket?Id=1754&p=g&comid=1384'
          width='100%'
          height='600px'
          title='Embedded Website'
          loading='lazy'
          allowFullScreen></iframe>
      </div> */}
      <div
            className='flex flex-col justify-center items-center
        gap-6 py-[4rem]'>
            <a target='_blank' href='https://vsprocessorpro.com/ev/BuyTicket?Id=1754&p=g&comid=1384' rel='noreferrer'>
              <Button>
                <h5 className='font-bold text-[16px] gradient-text'>Proceed &gt; </h5>
              </Button>
            </a>
          </div>
      <div className='w-[80%] mb-6'>
        <p className='xs:text-[12px] mb-4'> or USSD</p>
        <h4 className='xs:text-[24px] text-[32px] gradient-text-yellow font-black'>*714*11*23#</h4>
      </div>
    </div>
  );
};

export default Tickets;
