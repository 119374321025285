import React from "react";
import Header from "./Header";
import { BigButton } from "./svg/BigButton";
import Button from "./Button";
import VideoEmbed from "./VideoEmbed";
import Tickets from "./Tickets";
import LineUps from "./LineUps";

const Hero = () => {
  return (
    <section className='w-full h-full'>
      <div className='hero'>
        <Header />
        <div className='flex flex-col items-start justify-center h-[70vh] max-w-[1400px] mx-auto'>
          <h3 className=' shadowed  xs:text-[24px] text-[30px] lg:text-[36px] leading-8 font-bold md:leading-3'>
            Okokobioko:
          </h3>
          <div className='flex items-center justify-start'>
            <h1 className=' xs:text-[40px] text-[66px] lg:text-[96px] leading-[20px] font-black md:leading-3'>
              Standing{" "}
            </h1>
            <a
              target='_blank'
              href='https://vsprocessorpro.com/ev/BuyTicket?Id=1754&p=g&comid=1384'
              className='ml-[-13px] '
              rel='noreferrer'>
              <BigButton className={"lg:w-[185px] w-[100px] xs:w-[50px] lg:h-[185px] h-[130px] xs:h-[70px]"} />
            </a>
          </div>
          <div className='flex items-center justify-start'>
            <h1 className='xs:text-[40px] text-[66px] lg:text-[96px] leading-[20px] font-black md:leading-[32px]'>
              Strong{" "}
            </h1>
            <span className='xs:text-[40px] text-[66px] lg:text-[96px] leading-[20px] font-black md:leading-[32px] shadowed ml-5'>
              💪
            </span>
          </div>
        </div>
      </div>
      <div className='hero-wrap md:mt-[-10rem]'>
        <div className='w-full trailer-card '>
          <div className='trailer-placeholder w-full h-[50vh] xs:h-[40vh] max-h-[600px] video-responsive'>
            <VideoEmbed />
          </div>
          <div
            className='flex flex-col justify-center items-center
        gap-6 py-[4rem]'>
            <h4 className='font-black text-[32px] xs:text-[24px]'>September 21st | National Theatre</h4>
            <a target='_blank' href='https://vsprocessorpro.com/ev/BuyTicket?Id=1754&p=g&comid=1384' rel='noreferrer'>
              <Button>
                <h5 className='font-bold text-[16px] gradient-text'>Buy Ticket &gt; </h5>
              </Button>
            </a>
          </div>
        </div>
        <Tickets />
        <LineUps />
      </div>
    </section>
  );
};

export default Hero;
