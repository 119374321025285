import React from "react";

const Frame = ({ name, role, src, height }) => {
  return (
    <div className='gradient-border  bg-black'>
      <div className='bg-black p-2'>
        <img
          style={{ height, objectFit: "cover", objectPosition: "center" }}
          className='w-[350px]'
          src={src}
          alt='host'
        />
        <div className='flex justify-center items-center flex-col py-4 trailer-card'>
          <h5 className='font-bold text-[24px]'>{name}</h5>
          <p>{role}</p>
        </div>
      </div>
    </div>
  );
};

export default Frame;
