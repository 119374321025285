import React from "react";

const PartnerFrame = ({ src }) => {
  return (
    <div className='gradient-border  bg-black'>
      <div className='trailer-card p-2 flex items-center justify-center'>
        <img style={{ objectFit: "contain" }} className='w-[100px] xs:w-[200px] h-[100px]' src={src} alt='partner' />
      </div>
    </div>
  );
};

export default PartnerFrame;
