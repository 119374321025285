export const Prev = ({ onClick }) => (
  <svg
    className='cursor-pointer'
    onClick={onClick}
    width='54'
    height='54'
    viewBox='0 0 54 54'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path d='M16.1859 27.0008L32.0226 36.1441L32.0226 17.8575L16.1859 27.0008Z' fill='white' />
    <circle
      cx='26.3917'
      cy='26.3917'
      r='26.0555'
      transform='matrix(-1 0 0 1 53.1355 0.609009)'
      fill='url(#paint0_linear_38_53)'
      stroke='white'
      strokeWidth='0.672545'
    />
    <defs>
      <linearGradient
        id='paint0_linear_38_53'
        x1='26.3917'
        y1='0'
        x2='26.3917'
        y2='52.7835'
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='white' />
        <stop offset='0.5' stopColor='#737373' stopOpacity='0' />
        <stop offset='1' stopColor='white' />
      </linearGradient>
    </defs>
  </svg>
);
