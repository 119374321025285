import React from "react";
import SectionTitle from "./SectionTitle";
import PartnerSlide from "./PartnerSlide";

const Partners = () => {
  return (
    <section className='w-full relative flex flex-col items-center justify-center gap-10 mb-[8rem] mt-[-40px]'>
      <SectionTitle title={"Our Partners"} />
      <PartnerSlide />
    </section>
  );
};

export default Partners;
