import "./App.css";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Partners from "./components/Partners";
import PastEvents from "./components/PastEvents";

function App() {
  return (
    <div className='App'>
      <Hero />
      <PastEvents />
      <Partners />
      <Footer />
    </div>
  );
}

export default App;
