import React from "react";

const Button = ({ children, onClick, disabled }) => {
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className='px-10 py-4 yellow-gradient rounded-[50px] flex items-center justify-center gap-3'>
      {children}
    </button>
  );
};

export default Button;
