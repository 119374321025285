import React, { useState } from "react";
import { Prev } from "./svg/Prev";
import { Next } from "./svg/Next";

const Carousel = ({ images }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    console.log({ next: currentIndex, images });
    setCurrentIndex((prevIndex) => (prevIndex + 1 === images.length ? 0 : prevIndex + 1));
  };

  const handlePrevious = () => {
    console.log({ prev: currentIndex, images });
    setCurrentIndex((prevIndex) => (prevIndex - 1 < 0 ? images.length - 1 : prevIndex - 1));
  };

  const getNextIndex = (index) => (index + 1) % images.length;
  const getPrevIndex = (index) => (index - 1 + images.length) % images.length;

  return (
    <>
      <div className='relative h-full overflow-hidden py-12 bg-black mx-auto carousel' style={{ width: "100%" }}>
        <div className='absolute inset-0 z-20 before:absolute before:left-0 before:top-0 before:w-1/4 before:h-full before:bg-gradient-to-r before:from-black before:to-transparent before:filter before:blur-3 after:absolute after:right-0 after:top-0 after:w-1/4 after:h-full after:bg-gradient-to-l after:from-black after:to-transparent after:filter after:blur-3'></div>
        <div className='flex gap-4 items-center justify-center overflow-x-hidden ease-in-out duration-700'>
          <img
            key={currentIndex}
            src={images[currentIndex]}
            alt='carousel-image'
            className='w-[500px] h-[300px] object-cover'
          />
          <img
            key={getNextIndex(currentIndex)}
            src={images[getNextIndex(currentIndex)]}
            alt='carousel-image'
            className='w-[500px] h-[300px] object-cover'
          />
          <img
            key={getPrevIndex(currentIndex)}
            src={images[getPrevIndex(currentIndex)]}
            alt='carousel-image'
            className='w-[500px] h-[300px] object-cover'
          />
        </div>
      </div>
      <div className='slide_direction my-6'>
        <Prev onClick={handlePrevious} />
        <Next onClick={handleNext} />
      </div>
    </>
  );
};

export default Carousel;
