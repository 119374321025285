export const Next = ({ onClick }) => (
  <svg
    className='cursor-pointer'
    onClick={onClick}
    width='54'
    height='54'
    viewBox='0 0 54 54'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path d='M37.8141 27.0008L21.9774 36.1441L21.9774 17.8575L37.8141 27.0008Z' fill='white' />
    <circle
      cx='27.2562'
      cy='27.0007'
      r='26.0555'
      fill='url(#paint0_linear_38_50)'
      stroke='white'
      strokeWidth='0.672545'
    />
    <defs>
      <linearGradient
        id='paint0_linear_38_50'
        x1='27.2562'
        y1='0.609009'
        x2='27.2562'
        y2='53.3925'
        gradientUnits='userSpaceOnUse'>
        <stop stopColor='white' />
        <stop offset='0.5' stopColor='#737373' stopOpacity='0' />
        <stop offset='1' stopColor='white' />
      </linearGradient>
    </defs>
  </svg>
);
