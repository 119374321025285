import React from "react";

const VideoEmbed = () => {
  return (
    <iframe
      width='560'
      height='315'
      src='https://www.youtube.com/embed/SG-ihOX8Wuc?si=RAFk-9Gsx_FS_5id'
      title='YouTube video player'
      loading='lazy'
      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
      referrerPolicy='strict-origin-when-cross-origin'
      allowFullScreen></iframe>
  );
};

export default VideoEmbed;
