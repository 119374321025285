import React from "react";

const SectionTitle = ({ title }) => {
  return (
    <div className='flex items-center justify-center gap-6'>
      <span className='gradient-divider-left'></span>
      <h2 className='font-black text-[40px] xs:text-[24px]'>{title}</h2>
      <span className='gradient-divider-right'></span>
    </div>
  );
};

export default SectionTitle;
